<template>
  <div class="add-campaign">
    <SettingHeader> Add New Campaign </SettingHeader>
    <a-card title="Add Campaign">
      <template #extra>
        <div class="form-status">
          <div v-show="!isMobileScreen" class="form-row-title">Status:</div>
          <div class="form-row-input">
            <div class="d-flex align-items-center">
              <a-switch v-model:checked="statusCampaign" />
              <span v-show="!isMobileScreen" class="text-status"> Active</span>
            </div>
          </div>
        </div>
      </template>
      <a-row :gutter="25">
        <a-col :xs="24" :sm="24" :md="24" class="pd-bottom">
          <span class="row-text"><FeatherIcon class="mr-5" type="file-text" /> Campaign Overview</span>
        </a-col>
        <a-col :xs="24" :sm="24" :md="24">
          <span class="text-name">Campaign Name</span>
          <a-input v-model:value="campaignName" placeholder="Enter Campaign Name" class="mr-top"></a-input>
        </a-col>
        <a-col :xs="24" :sm="24" :md="24" class="pd-bottom pd-top">
          <span class="row-text"
            ><FeatherIcon class="mr-5" type="key" /> Campaign Keywords
            <a-popover>
              <template #content>
                <div class="row-popover">
                  <p>Separate word, phase, hashtag or username with a comma or by pressing enter</p>
                </div>
              </template>
              <FeatherIcon class="mr-5 ml-5 text-gray info-cursor" type="info" />
            </a-popover>
          </span>
        </a-col>

        <a-col :xs="24" :sm="24" :md="12" class="pd-column">
          <a-col :xs="24" :sm="24" :md="24" class="pd-bottom frame-keywords">
            <div class="col-text-input">
              <img :src="require(`../../assets/images/icon/keyword.svg`)" />
              <span class="head-text-input">Keyword</span>
            </div>
            <div class="chip-container">
              <div v-for="(chip, i) of wordKeywordList" :key="chip.label" class="chip">
                {{ chip }}
                <FeatherIcon type="x" size="10" color="#5A5F7D" @click="onKeywordRemove(i)" />
              </div>
              <!-- <textarea
                v-model="currentKeywordInput"
                :placeholder="wordKeywordList.length === 0 ? 'Enter Keywords' : ''"
                :rows="10"
                class="keyword-textarea"
                @paste="onKeywordPasteChange"
                @keypress="onKeywordChange"
                @keydown.delete="backspaceKeywordDelete"
              ></textarea> -->
              <input
                v-model="currentKeywordInput"
                :placeholder="wordKeywordList.length === 0 ? 'Enter Keywords' : ''"
                class="keyword-textarea"
                @paste="onKeywordPasteChange"
                @keypress="onKeywordChange"
                @keydown.delete="backspaceKeywordDelete"
              />
            </div>
          </a-col>
        </a-col>
        <a-col :xs="24" :sm="24" :md="12">
          <a-col :xs="24" :sm="24" :md="24" class="pd-bottom frame-exclude-keywords">
            <div class="col-text-input">
              <img :src="require(`../../assets/images/icon/exclude-keyword.svg`)" />
              <span class="head-text-input"><span class="color-text-exclude">Exclude</span> Keyword</span>
            </div>
            <div class="chip-container">
              <div v-for="(chip, i) of wordExcludeLists" :key="chip.label" class="chip">
                {{ chip }}
                <FeatherIcon type="x" size="10" color="#5A5F7D" @click="onExcludeRemove(i)" />
              </div>
              <!-- <textarea
                v-model="currentExcludeInput"
                :placeholder="wordExcludeLists.length === 0 ? 'Enter Exclude Keywords' : ''"
                :rows="10"
                class="keyword-textarea"
                @paste="onExcludePasteChange"
                @keypress="onExcludeChange"
                @keydown.delete="backspaceExcludeDelete"
              ></textarea> -->
              <input
                v-model="currentExcludeInput"
                :placeholder="wordExcludeLists.length === 0 ? 'Enter Exclude Keywords' : ''"
                class="keyword-textarea"
                @paste="onExcludePasteChange"
                @keypress="onExcludeChange"
                @keydown.delete="backspaceExcludeDelete"
              />
            </div>
          </a-col>
        </a-col>
      </a-row>

      <a-divider />
      <div class="text-right">
        <a-button
          :disabled="isDisabled"
          size="large"
          type="default"
          class="cancel-button mr-2"
          @click="cancelAddCampaign"
        >
          Cancel
        </a-button>
        <a-button :disabled="isDisabled" size="large" type="primary" class="confirm-button" @click="confirmAddCampaign"
          >Add Campaign</a-button
        >
      </div>
    </a-card>

    <a-modal v-model:visible="cancelVisible" class="modal-cancel-campaign" @ok="confirmCancel">
      <div class="icon-alert">
        <FeatherIcon class="floating-cancel-icon" type="info" :size="16" /><span class="alert-text"
          >Unsaved Changes</span
        >
      </div>
      <p class="alert-detail">Are you sure you want to discard this new campaign? Your changes will be lost.</p>

      <template #footer>
        <a-button key="back" @click="cancelBtnCampaign">Cancel</a-button>
        <a-button key="submit" type="warning" @click="handleDiscardAdd">Discard</a-button>
      </template>
    </a-modal>

    <a-modal v-model:visible="confirmVisible" class="modal-add-campaign" @ok="confirmAdd">
      <div class="icon-alert">
        <FeatherIcon class="floating-title-icon" type="check-circle" :size="16" /><span class="alert-text"
          >Success</span
        >
      </div>
      <p class="alert-detail">Your campaign has been added successfully.</p>
    </a-modal>
  </div>
</template>

<script>
import { ref, toRefs, computed, watch } from 'vue';
import SettingHeader from '@/views/Settings/SettingHeader.vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import api from '@/services/api';
import helper from '@/services/helper';
import { notification } from 'ant-design-vue';
export default {
  name: 'AddCampaign',
  props: {
    set: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { set } = toRefs(props);
    const store = useStore();
    const router = useRouter();
    let statusCampaign = ref(true);
    let cancelVisible = ref(false);
    let confirmVisible = ref(false);
    const wordKeywordList = ref([]);
    const wordExcludeLists = ref([]);
    let currentKeywordInput = ref('');
    let currentExcludeInput = ref('');
    let campaignName = ref('');
    let isDisabled = ref(true);
    const campaignLevel = computed(() => store.state.account.campaignMenuData.categoryLevel);
    const brand = computed(() => store.state.account.brand);
    const isMobileScreen = computed(() => store.state.isMobileScreen);

    const cancelAddCampaign = () => {
      if (campaignName.value || wordKeywordList.value.length !== 0 || wordExcludeLists.value.length !== 0) {
        cancelVisible.value = true;
      } else {
        router.push('/');
      }
    };

    const cancelBtnCampaign = () => {
      cancelVisible.value = false;
    };

    const confirmAddCampaign = async () => {
      const obj = {
        excludeKeyword: setExcludeLists(),
        keyword: setKeywordLists(),
      };
      const result = await api
        .addCampaign(obj, brand.value, campaignName.value, campaignLevel.value, statusCampaign.value)
        .catch((e) => {
          if (e.response.status === 400) {
            notification.error({
              message: 'Error',
              description: 'Campaign ' + `${campaignName.value}` + ' is duplicate.',
            });
          }
          if (e.response.status === 409) {
            notification.error({
              message: 'Error',
              description: 'Campaign ' + `${campaignName.value}` + ' keyword is duplicate.',
            });
          }
        });
      if (result) {
        confirmVisible.value = true;
      }
    };

    const setKeywordLists = () => {
      let keyList = [];
      if (wordKeywordList.value.length !== 0) {
        const kList = helper.prepareKeywordList({
          mode: 'word',
          list: wordKeywordList.value,
          sentimentValue: 0,
        });
        keyList = kList.map((k) => {
          return { word: k, value: 0 };
        });
        return keyList;
      }
      return keyList;
    };

    const setExcludeLists = () => {
      let keyList = [];
      if (wordExcludeLists.value.length !== 0) {
        const kList = helper.prepareKeywordList({
          mode: 'word',
          list: wordExcludeLists.value,
          sentimentValue: 0,
        });
        keyList = kList.map((k) => {
          return { word: k, value: 0 };
        });
        return keyList;
      }
      return keyList;
    };

    const toggleCloseModal = (state) => {
      cancelVisible.value = state;
    };

    const onKeywordChange = () => {
      const key = window.event.keyCode;
      let value = currentKeywordInput.value.trim();
      if (key === 13 || key === 32) {
        if (value.length > 0) {
          ((set && wordKeywordList.value.indexOf(value) === -1) || !set) && wordKeywordList.value.push(value);
          value = '';
          currentKeywordInput.value = value.trim();
        }
      }
    };

    const onKeywordPasteChange = (e) => {
      e.preventDefault();
      let pastedText = '';
      if (e.clipboardData && e.clipboardData.getData) {
        pastedText = e.clipboardData.getData('text/plain');
      } else if (window.clipboardData && window.clipboardData.getData) {
        // IE
        pastedText = window.clipboardData.getData('Text');
      }
      for (let value of pastedText.split(',')) {
        if (value.length > 0) {
          ((set && wordKeywordList.value.indexOf(value.trim()) === -1) || !set) &&
            wordKeywordList.value.push(value.trim());
          value = '';
          currentKeywordInput.value = value.trim();
        }
      }
    };

    const onExcludePasteChange = (e) => {
      e.preventDefault();
      let pastedText = '';
      if (e.clipboardData && e.clipboardData.getData) {
        pastedText = e.clipboardData.getData('text/plain');
      } else if (window.clipboardData && window.clipboardData.getData) {
        // IE
        pastedText = window.clipboardData.getData('Text');
      }
      for (let value of pastedText.split(',')) {
        if (value.length > 0) {
          ((set && wordExcludeLists.value.indexOf(value.trim()) === -1) || !set) &&
            wordExcludeLists.value.push(value.trim());
          value = '';
          currentExcludeInput.value = value.trim();
        }
      }
    };

    const onKeywordRemove = (index) => {
      wordKeywordList.value.splice(index, 1);
    };

    const backspaceKeywordDelete = ({ which }) => {
      which == 8 && currentKeywordInput.value === '' && wordKeywordList.value.splice(wordKeywordList.value.length - 1);
    };

    const onExcludeChange = () => {
      const key = window.event.keyCode;
      let value = currentExcludeInput.value.trim();
      if (key === 13 || key === 32) {
        if (value.length > 0) {
          ((set && wordExcludeLists.value.indexOf(value) === -1) || !set) && wordExcludeLists.value.push(value);
          value = '';
          currentExcludeInput.value = value.trim();
        }
      }
    };

    const onExcludeRemove = (index) => {
      wordExcludeLists.value.splice(index, 1);
    };

    const backspaceExcludeDelete = ({ which }) => {
      which == 8 &&
        currentExcludeInput.value === '' &&
        wordExcludeLists.value.splice(wordExcludeLists.value.length - 1);
    };

    const confirmAdd = async () => {
      confirmVisible.value = false;
      await store.dispatch('account/getCampaignMenu');
      router.push(`/campaign/${encodeURIComponent(campaignName.value)}`);
    };

    const handleDiscardAdd = () => {
      cancelVisible.value = false;
      router.push('/campaign/comparison');
    };

    watch(() => {
      if (
        campaignName.value.length !== 0 ||
        wordKeywordList.value.length !== 0 ||
        wordExcludeLists.value.length !== 0
      ) {
        isDisabled.value = false;
      } else {
        isDisabled.value = true;
      }
    });

    return {
      statusCampaign,
      cancelAddCampaign,
      confirmAddCampaign,
      cancelVisible,
      confirmVisible,
      toggleCloseModal,
      cancelBtnCampaign,
      onKeywordChange,
      wordKeywordList,
      wordExcludeLists,
      currentKeywordInput,
      currentExcludeInput,
      onKeywordRemove,
      backspaceKeywordDelete,
      onExcludeChange,
      onExcludeRemove,
      backspaceExcludeDelete,
      campaignName,
      confirmAdd,
      handleDiscardAdd,
      isMobileScreen,
      isDisabled,
      onKeywordPasteChange,
      onExcludePasteChange,
    };
  },
  components: {
    SettingHeader,
  },
};
</script>

<style lang="scss">
textarea {
  resize: none;
}
.ant-popover-placement-top > .ant-popover-content:has(div > div > div > div.row-popover) > .ant-popover-arrow {
  border-right-color: #272b41;
  border-bottom-color: #272b41;
}
.ant-popover-inner:has(div > div > div.row-popover) {
  border-radius: 2px;
  background-color: #272b41;
}
.ant-popover-inner-content {
  padding: 0px;
}

.info-cursor {
  cursor: pointer;
}
.ant-modal-content {
  padding: 20px;
}
.ant-modal-body {
  border: none;
  .floating-title-icon {
    color: #20c977;
  }

  .floating-cancel-icon {
    color: #fa8b0c;
  }
}

.modal-add-campaign .ant-modal-content .ant-modal-footer {
  border: none;
  div {
    .ant-btn:first-child {
      display: none;
    }
    .ant-btn-primary {
      background: #20c997;
      padding: 12px 32px;
    }
  }
}

.chip-container {
  height: 100%;
  min-height: 250px;
  overflow: auto;
  border: 1px solid #e3e6ef;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  background: #fff;
  padding: 12px 11px;
  border-radius: 4px;
  .chip {
    font-family: 'DM Sans', 'IBM Plex Sans Thai', sans-serif;
    height: 20px;
    margin: 0 4px 10px 7px;
    background: #eff0f3;
    padding: 4px 8px;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #5a5f7d;
    i {
      cursor: pointer;
      margin-left: 4px;
      line-height: 14px !important;
    }
  }
  .keyword-textarea {
    height: 20px;
    padding: 0;
    flex: 1 1 auto;
    border: none;
    outline: none;
    white-space: normal;
    &::placeholder {
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      color: #9299b8;
    }
  }
}

.ant-modal-close-x {
  display: none;
}

.ant-modal-footer {
  border-top: unset;
}

.icon-alert {
  display: flex;
  align-items: center;
}

.alert-text {
  font-size: 16px;
  font-weight: 500;
  margin-left: 10px;
}

.alert-detail {
  color: #5a5f7d;
  margin-left: 25px;
}
.add-campaign {
  padding: 30px;
  overflow: auto;
  height: 100%;
  width: 100%;
}
.form-status {
  display: flex;
  align-content: center;
}
.form-row-title {
  font-size: 14px;
  font-weight: 500;
  color: #272b41;
  margin-right: 5px;
}
.text-status {
  font-size: 13px;
  font-weight: 400;
  color: #5a5f7d;
  margin-left: 5px;
}

.text-name {
  font-weight: 500;
  color: #272b41;
}

.row-text {
  font-size: 16px;
  font-weight: 500;
  color: #3371ff;
  display: flex;
  align-items: center;
}

.mr-5 {
  margin-right: 5px;
}

.ml-5 {
  margin-left: 5px;
}

.text-gray {
  color: #9299b8;
}

.mr-top {
  margin-top: 10px;
}

.pd-bottom {
  padding-bottom: 25px;
}

.pd-top {
  padding-top: 25px;
}

.row-popover {
  width: 250px;
  height: 78px;
  color: #fff;
  background-color: #272b41;
  padding: 6px 8px 6px 8px;
}

.ant-switch-checked.ant-switch {
  background-color: #20c997;
}

.frame-keywords {
  border: 1px solid #e0eaff;
  background-color: #f5f8ff;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-top: 20px;
  //   margin: 20px;
  border-radius: 8px;
}

.frame-exclude-keywords {
  border: 1px solid #feeedb;
  background-color: #fff9f3;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-top: 20px;
  //   margin: 20px;
  border-radius: 8px;
}

.head-text-input {
  font-size: 16px;
  font-weight: 500;
  color: #272b41;
  margin-left: 10px;
}
.col-text-input {
  margin-bottom: 20px;
  display: flex;
}

.frame-keywords .ant-input:hover,
.frame-exclude-keywords .ant-input:hover {
  border: 1px solid #e3e6ef;
}

.cancel-button {
  background-color: #f1f2f6;
  font-size: 14px;
}
.confirm-button {
  font-size: 14px;
}

.color-text-exclude {
  color: #fa8b0c;
}

@media only screen and (max-width: 768px) {
  .frame-keywords,
  .frame-exclude-keywords {
    margin: unset;
  }

  .form-status {
    align-items: center;
  }
  .pd-column {
    padding-bottom: 12.5px;
  }
}

@media only screen and (max-width: 575px) {
  .ant-card-head-wrapper {
    flex-flow: unset;
  }

  .ant-card-head-wrapper .ant-card-extra {
    padding-top: 20px !important;
  }
}
</style>
